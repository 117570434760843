<template>
	<div>

        <div class="container-fluid">
            <div class="card mt-3">

                <div class="card-body">
                    <h4 class="card-title">Non-inventory Items</h4>
                    
                    <div class="table-responsive data-table table-sm table-striped">
                        <div id="data-table_wrapper" class="dataTables_wrapper no-footer">
                            <div class="dataTables__top">
                                <div id="data-table_filter" class="dataTables_filter">
                                    <label>Search:<input type="search" class="" v-model="searchterm" v-on:keyup.enter="search" placeholder="Search for records..." aria-controls="data-table"></label>
                                </div>
                            </div>
                            <table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <thead class="table-dark">
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Company</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Description</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Serial No.</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Comments</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            <tr v-for="item in noninventory_list.list" v-bind:key="item">
                                <td>
                                    {{company_name(item.company_id)}}
                                </td>
                                <td>
                                    {{item.description || ''}}
                                </td>
                                <td>
                                    {{item.serial_no || ''}}
                                </td>
                                <td>
                                    {{item.comments || ''}}
                                </td>
                                <td>
                                    <a class="btn btn-sm btn-primary float-right" :href="'/ticket/view/'+item.shipping_ticket_id" target="_blank"><i class="zwicon-send"></i> View Shipping Ticket</a>
                                </td>
                                
                                
                            </tr>
                            </tbody>
                        </table>
                            <div class='row mt-2' v-if='noninventory_list.pagination !== undefined'>
                                <div class='col-md-6 pt-2'>Showing {{(noninventory_list.pagination.per_page*noninventory_list.pagination.page)+1}} to {{pagination_max}} of {{noninventory_list.pagination.total_items}} items.</div>
                                <div class='col-md-6'>
                                    <div class="ml-auto" v-if='pages.length > 1'>
                                        <Pagination @set_page="view_page" :current_page='noninventory_list.pagination.page' :pages='pages' />
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

	import { LOAD_NONINVENTORY_LIST,LOAD_COMPANY_LIST } from "@/store/actions.type";

	import Pagination from '@/components/General/Pagination';

	export default {
		name: 'NonInventory',
		components: {
            Pagination
		},
		props: {
			error: {
				type: String,
				default: "" 
			},
			success: {
				type: String,
				default: ""
			}
		},
        data(){
            return {
                filter_list: false,
                active_item: '',
                show_view: false,
                show_add: false,
                show_edit: false,
                show_delete: false,
                show_filter_modal: false,
                success_msg: '',
				filter_error: '',
                filter: {
                    search: ''
                },

                searchterm: ''
            }
        },
        beforeMount() {
            this.load_company_list(function(){
                
			}, function(){
                
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load company list." }});
			});

            this.reset_filter_form();
			this.load_list(function(){
                console.log('try to load')
			}, function(){
                console.log('load error')
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load inventory list." }});
			});
		},
		computed: {
            pagination_max(){
				var page_max = (this.noninventory_list.pagination.per_page*(this.noninventory_list.pagination.page+1));
				if(page_max > this.noninventory_list.pagination.total_items)  page_max = this.noninventory_list.pagination.total_items;
				return page_max;
			},
			pages(){
				let page_list = [];
				for(var i = 0;i < Math.ceil(this.noninventory_list.pagination.total_items / this.noninventory_list.pagination.per_page);i++){
					page_list.push((i*1)+1);
				}
				return page_list;
			},
			...mapGetters(['company_list','noninventory_list', 'user', 'loading'])
		},
        methods: {
            toggle_v_dropdown(id){
                if(this.active_item == id) this.active_item = '';
                else this.active_item = id;
            },
            reset_filter_form(){
				this.filter = {
					page: 0,
					search: '',
					sort_by: 'id',
					sort_dir: 'DESC'
				};
			},
			open_filters(){
				this.show_filter_modal = true;
			},
			close_filters(){
				this.show_filter_modal = false;
			},
            company_name(desc_id){
                if(this.company_list.list === undefined) return "";
                let item = this.company_list.list.filter(x => x.company_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            load_list(success_fn, failure_fn){
				this.list_loading = true;
				this.filter_error = "";
				console.log('load list');
                this.filter.search = this.searchterm;
				store.dispatch(LOAD_NONINVENTORY_LIST, this.filter).then(data => {
					success_fn(data);
				}).catch(message => {
                    console.log(message);
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
            load_company_list(success_fn, failure_fn = () => {}){
				this.list_loading = true;
				this.filter_error = "";
				
				store.dispatch(LOAD_COMPANY_LIST).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
            view_page(page){
				this.filter.page = page;
				this.load_list(this.close_filters, function(){
					this.success_msg = '';
					this.filter_error = '';
				});
			},
            search(){
                this.load_list(() => {}, ()=>{});
            },
            clear_search(){
                this.filter.search = '';
                this.search();
            }
        },
        watch: {
            searchterm(newdata){
                if(newdata != '') return;
                this.search();
            }
        }
	}
</script>